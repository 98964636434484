<template>
  <div class="login-right-comp">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'loginRight'
};
</script>

<style scoped>
  .login-right-comp {
    width: 600px;
    /*由于需求存在一个不同高度的页面，所以不能在此设置统一高度，故注释*/
    /*height: 480px;*/
  }
</style>
