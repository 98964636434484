<template>
  <div class="login-index">
    <login-tem>
      <!-- <login-left slot="login-left">
        <login-left-normal></login-left-normal>
      </login-left> -->
      <login-right slot="login-right">
        <router-view></router-view>
      </login-right>
    </login-tem>
  </div>
</template>

<script>

import loginTem from './loginComp/loginTem';
import loginRight from './loginComp/loginRight';
import loginLeft from './loginComp/loginLeft';
import loginLeftNormal from './loginComp/loginLeftNormal';

export default {
  components: {
    loginTem,
    loginRight,
    loginLeft,
    loginLeftNormal
  },
  data () {
    return {};
  },
  mounted () {},
  methods: {}
};
</script>

<style lang="less" scoped>
 .login-index {
   padding: 42px 0px 60px;
   background-color: #FFFFFF;
 }
</style>
