<template>
  <div class="login flex">
    <slot name="login-left"></slot>
    <slot name="login-right"></slot>
  </div>
</template>

<script>
export default {
  name: 'loginTem'
};
</script>

<style lang="less" scoped>
  .login {
    border: 1px solid #DFE6EE;
    box-shadow: 0px 0px 10px 0px rgba(0, 26, 68, 0.08);
    border-radius: 5px;
  }
</style>
